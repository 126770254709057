import { SpecialAction, SpecialActionContext } from '../special-action';

const RIGHT_SALES = 'AKTION_HANDEL';
const RIGHT_PARTNER = 'AKTION_PARTNER';
export const FROM_DATE = new Date(2024, 9, 7).getTime();
export const TILL_DATE = new Date(2024, 9, 26).getTime();

export class TorchAction extends SpecialAction {
    shouldShow(ctx: SpecialActionContext): boolean {
        return this.isQualified(ctx.userRoles) && this.isInDateRange();
    }

    getImagePath(_ctx: SpecialActionContext): string {
        return `./assets/Naue_Customercenter_Stirnlampe_Aktion.jpg`;
    }

    private isQualified(roles: string[]): boolean {
        return [RIGHT_SALES, RIGHT_PARTNER].some(r => roles.includes(r));
    }

    private isInDateRange(): boolean {
        const now = new Date().getTime();
        return now >= FROM_DATE && now < TILL_DATE;
    }
}
